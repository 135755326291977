<template>
  <b-card title="List User">
    <b-col cols="12">
      <b-row class="mb-2">
        <b-col cols="4">
          <b-button
            variant="success"
            :to="{ name: 'users-add' }"
            v-if="permission.add"
          >
            Add User
          </b-button>
        </b-col>
        <b-col cols="2" />
        <b-col cols="3">
          <b-form-input
            v-model="search"
            type="search"
            placeholder="Type to Search"
          />
        </b-col>
        <b-col cols="3">
          <b-button @click="userFilter" class="mr-2" variant="primary">Search</b-button>    
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-table
            select-mode="multi"
            selectable
            ref="selectableTable"
            @row-selected="onRowSelected"
            :items="user"
            :fields="fields"
            :busy.sync="tableLoading"
            responsive
          >
            <template #cell(roleRef)="row">
              <span v-if="row.item.roleRef"
                >{{ row.item.roleRef.name }} -
                {{ row.item.roleRef.level }}</span
              >
              <span v-else style="color:red;"
                >{{ row.item.role }} (INVALID)</span
              >
            </template>

            <template #cell(actions)="row">
              <b-dropdown
                id="dropdown-dropleft"
                variant="primary"
                size="sm"
                text="Menu"
                no-caret
              >
                <template #button-content>
                  <feather-icon size="1x" icon="MenuIcon" />
                </template>
                <b-dropdown-item
                  style="text-align:center"
                  v-if="permission.edit"
                >
                  <b-button
                    class="mr-1"
                    variant="outline-primary"
                    :to="{ name: 'users-edit', params: { id: row.item.id } }"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                </b-dropdown-item>
                <b-dropdown-item
                  style="text-align:center"
                  v-if="permission.delete"
                >
                  <b-button
                    @click="promptDeleteUser(row)"
                    class="mr-1"
                    variant="danger"
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </b-col>

        <b-col cols="12">
          <b-pagination
            :total-rows="totalRows"
            :per-page="perPage"
            @change="onPageChange"
            v-model="currentPage"
            style="float: right"
          />
        </b-col>
      </b-row>
      <!-- <b-row><span>selectable</span><span>{{selected}}</span></b-row> -->
    </b-col>

    <b-modal ref="modal" v-model="promptDelete" title="Confirm Delete User">
      <p class="my-2">
        Delete user ?
      </p>
      <template #modal-footer>
        <b-button size="md" variant="danger" @click="doDeleteUser">
          Yes
        </b-button>
        <b-button size="md" variant="success" @click="promptDelete = false">
          Cancel
        </b-button>
      </template>
    </b-modal>
  </b-card>
</template>

<script>
import { mapActions, mapState,mapGetters } from "vuex";
import { userAccess } from "@/utils/utils";

export default {
  data() {
    return {
      selected: [],
      admins: [],
      fields: [
        { key: "name", text: "Name", filterable: true, sortable: true },
        { key: "email", text: "Email", filterable: true, sortable: true },
        { key: "roleRef", text: "Role", filterable: true, sortable: true },
        { key: "actions", text: "Actions" },
      ],
      search: "",
      promptDelete: false,
      deleteUserId: "",
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      totalData:0,
      tableLoading:false
    };
  },
  mounted() {
    this.getAllUsers({
      page:this.currentPage,
      entry:this.perPage
    }).then((data) => this.processMetadata(data.metadata));;
    // .then((data) => {
    //   console.log(data)
    //   this.admins = data
    //   console.log('data',this.admins)
    //   console.log('data length',this.admins.length)
    // }).catch((e) => {
    //   console.log(e)
    // })
    //   this.getUsersBy({ role: 'Admin' }).then((data) => {
    //   this.admins = data
    // }).catch((e) => {
    //   console.log(e)
    // })
  },
  computed: {
    ...mapGetters({
      user: 'auth/getUserList',
    }),
    permission() {
      return userAccess("User Management", "settings_menu");
    },
  },
  methods: {
    onRowSelected(items) {
      this.selected = items;
    },
    ...mapActions({
      getAllUsers: "auth/fetchAllUser",
      deleteUser: "auth/deleteUser",
      getUsersBy: "auth/getUsersBy",
    }),
    userFilter(){
      this.currentPage=1;
      this.getAllUsers({
        page:this.currentPage,
        entry:this.perPage,
        filter:this.search
      }).then((result)=>{
        this.processMetadata(result.metadata)
      });
    },
    onPageChange(page) {
      this.currentPage=page
      this.getUserData()
    },
    processMetadata(metadata) {
      let { totalData } = metadata;
      this.totalRows = totalData;
      this.tableLoading = false;
    },
    promptDeleteUser(row) {
      this.deleteUserId = row.item.id;
      this.promptDelete = true;
    },
    getUserData(){
      this.getAllUsers({ 
        page:this.currentPage,
        entry:this.perPage,
        filter:this.search,
      }).then((data) => this.processMetadata(data.metadata));
    },
    doDeleteUser() {
      this.deleteUser(this.deleteUserId)
        .then(() => {
          this.promptDelete = false;
          this.$bvToast.toast("Successfully Delete User", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          this.currentPage=1;
          this.getUserData()
          // this.getUsersBy({ role: 'Admin' }).then((data) => this.admins = data).catch((e) => console.log(e))
        })
        .catch((e) => {
          console.error(e);
          this.$bvToast.toast(`Delete User Failed`, {
            title: "Fail",
            variant: "danger",
            solid: true,
          });
        });
    },
  },
  created() {
    document.title = "User | RSP";
  },
};
</script>
